@import "../../../styles/vars";

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 24px 0 8px;
  background-color: $bg-color;

  &__box {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.nav__box {
  position: absolute;
  top: 0;
  right: -100%;
  width: 100vw;
  height: 100vh;
  background-color: $bg-color;
  transition: right 250ms ease-in-out;

  &--active {
    right: 0;
  }
}

.nav__close {
  margin-top: 24px;
  margin-left: 24px;
}

.nav__list {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
}

.nav__link, .nav__hire-btn {
  color: $main-color;
  font-size: 56px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 250ms ease-in-out;
  &:hover, &:focus {
    color: $accent-color;
  }
}

.nav__hire-btn {
  margin: 48px auto 0;
  display: block;
}

.nav__img {
  margin-top: 48px;
  padding-left: 12px;
  padding-right: 12px;
}

@media screen and (min-width: 820px) {
  .header {
    padding: 16px 0 12px;
  }
  .header__box {
    padding: 0 24px;
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
  .header__logo {
    width: 110px;
    height: 36px;
  }
  .nav__menu-btn, .nav__close, .nav__img {
    display: none;
  }
  .nav__box {
    position: static;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: auto;
  }
  .nav__list {
    margin-top: 0;
    gap:5px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .nav__item:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    &::after {
      content: '';
      display: block;
      width: 28px;
      height: 1px;
      background-color: $main-color;
    }
  }
  .nav__link {
    font-size: 18px;
    line-height: 1;
  }
  .nav__hire-btn {
    margin: 0 0 0 auto;
    font-size: 18px;
  }
}
@media screen and (min-width: 1024px) {
  .header__box {
    padding: 0 84px;
  }
}
@media screen and (min-width: 1440px) {
  .header {
    padding: 24px 0 16px;
  }
  .header__box {
    padding: 0 120px;
  }
  .header__logo {
    width: 160px;
    height: 50px;
  }
  .nav__link, .nav__hire-btn {
    font-size: 24px;
  }
  .nav__list, .nav__item:first-child {
    gap: 8px
  }
  .nav__item:first-child::after {
    width: 40px;
  }
}
@media screen and (min-width: 1920px) {
  .header {
    padding: 32px 0 20px;
  }
  .header__box {
    padding: 0 160px;
  }
  .header__logo {
    width: 214px;
    height: 68px;
  }
  .nav__link, .nav__hire-btn {
    font-size: 32px;
  }
  .nav__list, .nav__item:first-child {
    gap: 11px
  }
  .nav__item:first-child::after {
    width: 52px;
    height: 2px;
  }
}